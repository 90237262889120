import { roleOptions } from '@/utils/autoMatchDepositHelpers';
import { SelectField } from '@availity/select';
import { useFormikContext } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';

const MatchPercentagesField: React.FC = () => {
  const { values, setFieldValue } = useFormikContext(); // Use Formik context from @availity/form
  const matchPercentages = values?.autoMatchDeposit?.matchPercentages || [];
  // Get non-empty roles for filtering
  const existingRoles = matchPercentages
    .map((item) => item.role)
    .filter((role) => role !== '');

  // Check if the last role is fully filled
  const canAddNewRole =
    matchPercentages.length === 0 || // Allow if no roles exist
    (matchPercentages[matchPercentages.length - 1]?.role &&
      matchPercentages[matchPercentages.length - 1]?.percentage);

  const addRole = () => {
    setFieldValue('autoMatchDeposit.matchPercentages', [
      ...matchPercentages,
      { role: '', percentage: '' },
    ]);
  };

  const removeRole = (index) => {
    const updatedRoles = [...matchPercentages];
    updatedRoles.splice(index, 1);
    setFieldValue('autoMatchDeposit.matchPercentages', updatedRoles);
  };

  return (
    <Col>
      <div>
        {matchPercentages.map((item, index) => {
          // Dynamically filter available roles
          const availableRoles = roleOptions.filter(
            (role) =>
              !existingRoles.includes(role.value) ||
              role.value === matchPercentages[index]?.role
          );

          return (
            <Row key={index} className="mb-3">
              <Col xs={5}>
                <SelectField
                  name={`autoMatchDeposit.matchPercentages[${index}].role`}
                  options={availableRoles}
                  valueKey="value"
                  labelKey="label"
                  value={availableRoles.find(
                    (option) => option.value === matchPercentages[index]?.role
                  )}
                  onChange={(selectedOption) => {
                    const updatedRoles = [...matchPercentages];
                    updatedRoles[index].role = selectedOption;
                    setFieldValue(
                      'autoMatchDeposit.matchPercentages',
                      updatedRoles
                    );
                  }}
                  placeholder="Select Role"
                />
              </Col>

              <Col xs={3}>
                <InputGroup size="md" style={{ minWidth: '100px' }}>
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Percentage"
                    value={item.percentage || ''}
                    onChange={(e) => {
                      const updatedRoles = [...matchPercentages];
                      updatedRoles[index].percentage =
                        e.target.value !== '' ? Number(e.target.value) : '';
                      setFieldValue(
                        'autoMatchDeposit.matchPercentages',
                        updatedRoles
                      );
                    }}
                  />
                </InputGroup>
              </Col>
              <Col xs={3}>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => removeRole(index)}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          );
        })}
        <Row>
          <Col>
            <Button
              type="button"
              color="primary"
              onClick={addRole}
              disabled={
                !canAddNewRole || existingRoles.length >= roleOptions.length
              } // Check if the last role is complete and prevent duplicates
            >
              Add Role
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default MatchPercentagesField;
