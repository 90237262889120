export { default as Vars } from './env-var';

export const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const MAX_CENTS = 1000000;

export const formatCurrencyForDisplay = (
  centsInteger: number,
  showCents = true
): string =>
  `$${(centsInteger / 100)
    .toFixed(showCents && centsInteger < MAX_CENTS ? 2 : 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const MS_PER_DAY = 86400000;

export const MS_PER_YEAR = MS_PER_DAY * 365;

export const MS_PER_99_YEARS = MS_PER_YEAR * 99;

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toUTCDate = (
  date: string,
  startOfDay: boolean,
  daylightSavings = 0
): string => {
  const localDate = new Date(date); // This will be in the local timezone
  const localTime = localDate.getTime();
  const timezoneOffset = localDate.getTimezoneOffset() * 60000;
  const newTime =
    localTime + timezoneOffset + daylightSavings + (startOfDay ? 0 : 86400000);
  console.log(`newTime ${newTime}`);
  const finalDate = new Date(newTime);
  console.log(`finalDate ${finalDate}`);
  console.log(`finalDate toISOString ${finalDate.toISOString()}`);
  return finalDate.toISOString();
};

export const daysOfWeek = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];
